import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';
import utils from '@/components/Utils/utils';


export class AddressEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.AGENT,
        save: URLs.PARTNER_AGENT_ADDRESS,
        update: URLs.PARTNER_AGENT_ADDRESS,
        delete: URLs.PARTNER_AGENT_ADDRESS,
        getEntity: URLs.PARTNER_AGENT_ADDRESS,
    };

    createEntityObj(entity = null) {
        let returnObj = {};

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : '';
        returnObj.address_type = isPresent(entity) && isPresent(entity.address_type) ? entity.address_type : 'BASE';
        returnObj.zip_code = isPresent(entity) && isPresent(entity.zip_code) ? entity.zip_code : '';
        returnObj.city = isPresent(entity) && isPresent(entity.city) ? entity.city : '';
        returnObj.address = isPresent(entity) && isPresent(entity.address) ? entity.address : '';
        returnObj.contact_phone = isPresent(entity) && isPresent(entity.contact_phone) ? entity.contact_phone : '';
        returnObj.contact_email = isPresent(entity) && isPresent(entity.contact_email) ? entity.contact_email : '';

        return returnObj;
    }

    deleteEntity(entity, deleteAddress) {
        this.log.debug('EntityBase - addressDeleteEntity');
        this.log.debug(entity);

        return new Promise((resolve, reject) => {
            this.delete(entity, deleteAddress)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    this.log.error(error);
                    reject(error);
                })
                .finally(() => {});
        });
    }

    delete(agentEntity, entity) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - delete');
        this.log.debug(entity);
        let url = this.apiURLs.delete;

        if (isPresent(agentEntity.partner_id) && isPresent(agentEntity.id)) {
            let partnerId = agentEntity.partner_id;
            let agentId = agentEntity.id;
            url = eval(`\`${this.apiURLs.delete}\``);
        }
        url = url + "/" + entity.id;
        return utils.apiCaller(constants.HTTP_METHODS.DELETE, url)
    }
}
