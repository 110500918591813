import ProductList from '@/components/Products/ProductList';
import { AgentEntity } from '@/components/Agents/AgentEntity';
import { AddressEntity } from '@/components/Agents/AddressEntity';
import { PartnerEntity } from '@/components/Partners/PartnerEntity';
import { SystemEntity } from '@/components/Agents/SystemEntity';
import { isPresent } from '@/components/Utils/utils';
import DialogBase from '@/commons/DialogBase';
import VerificationDialog from '@/components/CustomVerificationDialog';

import moment from 'moment-timezone';


export default {
    name: 'AgentDetails',
    extends: DialogBase,
    components: {
        ProductList,
        VerificationDialog
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        entity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            form: {
                general: true,
                personalInformation: true,
                taxAndRegistration: true,
                addresses: true,
                roles: true,
                table: true,
                mobileData: false
            },
            partner: {
                list: [],
                showDetails: null,
                options: [],
                selected: {},
            },
            agentTypesTechnical: [
                { key: 'Y' },
                { key: 'N' },
            ],
            roles: [],
            systems: [],
            role: {
                systems: {},
                list: [],
            },
            addressKey: 0,
            deviceKey: 0,
            entityHandler: new AgentEntity(this.$log, this.$store),
            addressEntityHandler: new AddressEntity(this.$log, this.$store),
            partnerEntityHandler: new PartnerEntity(this.$log, this.$store),
            systemEntityHandler: new SystemEntity(this.$log, this.$store),
            birthDateMenu: false,
            editedEntity: { mobileData: {} },
            dialog: {
                delete: false
            },
            deleteAddressIndex: -1
        }
    },

    created() {
        this.initSystemAndRoles();
    },

    computed: {
        isEdit() {
            return this.value.edit;
        },
        isOpenDetails() {
            return this.value.details;
        },
        itemForDelete() {
            let res = "";
            if (this.deleteAddressIndex != -1) {
                let address = this.editedEntity.addresses[this.deleteAddressIndex];
                if (isPresent(address)) {
                    res = address.zip_code + " " + address.city + " " + address.address;
                }
            }
            return res;
        }
    },

    methods: {
        initPartners() {
            this.partner.selected = {};
            this.partnerEntityHandler.listAll().then(entities => {
                this.partners = entities.data.data;
            });
        },

        initSystemAndRoles() {
            this.systemEntityHandler.systemRoles().then(entities => {
                this.systems = entities.data.data;
            });
        },

        getExpirationDateState(expirationDate) {
            if (moment(expirationDate).isSameOrAfter(moment().add(7, 'days'))) {
                return { color: "success", icon: "mdi-circle" };
            } else if (moment(expirationDate).isSameOrAfter(moment())) {
                return { color: "warning", icon: "mdi-circle" };
            } else if (moment(expirationDate).isBefore(moment())) {
                return { color: "error", icon: "mdi-circle" };
            } else {
                return { color: "", icon: "mdi-circle" };
            }
        },

        addNewAddress() {
            if (!this.form.addresses) {
                this.form.addresses = true;
            }
            if (this.editedEntity.addresses == undefined) {
                this.editedEntity.addresses = [];
            };
            this.editedEntity.addresses.push({
                id: "",
                address_type: "BASE",
                zip_code: "",
                city: "",
                address: "",
                contact_phone: "",
                contact_email: "",
            })
            this.addressKey++
        },

        deleteAddress(index) {
            this.$log.debug('DataComponentBase of DataComponentBase - deleteSelectedAddress');
            let deleteAddress = this.editedEntity.addresses[index];
            this.$log.debug(deleteAddress);

            if (deleteAddress.id != ""){
                // if address has ID, then item is in database
                this.addressEntityHandler
                    .deleteEntity(this.editedEntity, deleteAddress)
                    .then((response) => {
                        this.editedEntity.addresses.splice(index, 1);
                        this.deleteAddressIndex = -1;
                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                    })
                    .catch((error) => {
                        this.$log.error(error);
                        this.$store.commit('setStatusCode', error.getCode());
                    })
                    .finally(() => { });
            } else {
                // if address item is not in database, has in client memory
                this.editedEntity.addresses.splice(index, 1);
                this.deleteAddressIndex = -1;
            }
        },

        togglePartnerDetails() {
            if (this.partner.showDetails == null) {
                this.partner.showDetails = 0;
            } else {
                this.partner.showDetails = null;
            }
        },

        openDialogNew() {
            this.$log.debug(this.constructor.name + ' - openDialogNew');
            this.clearDialog();
            this.editedEntity = this.entityHandler.createEntityObj(this.entity);
            this.value.edit = false;
            this.value.details = true;
        },

        openDialogDetails(entity) {
            this.value.edit = true;
            this.value.details = true;
            this.loadEntityData(entity);
        },

        loadEntityData(entity) {
            this.entityHandler.getEntity(entity).then(entityData => {
                this.editedEntity = this.entityHandler.createEntityObj(entityData);
                this.editedEntity.roles = this.editedEntity.roles.map(el => { return el.id });
            });
            // this.$forceUpdate();
        },

        validateReenteredPassword(password, confirmPassword) {
            if (password == confirmPassword) {
                return true;
            } else {
                return this.$i18n.t('validation.reenteredPassword');
            }
        },

        updateAgentProductList(updateAgentProductList) {
            this.editedEntity.products = updateAgentProductList
        },

        _saveEditedEntityCreateEntityObj(editedEntity) {
            return this.entityHandler.createEntityObjEditAndNew(editedEntity)
        },

        _saveNewEntityCreateEntityObj(editedEntity) {
            return this.entityHandler.createEntityObjEditAndNew(editedEntity)
        },

        saveNewEntity() {
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - saveNewEntity');
            if (this.$refs.form.validate()) {
                const newEntity = this._saveNewEntityCreateEntityObj(this.editedEntity);

                this.entityHandler
                    .saveEntity(newEntity)
                    .then(response => {

                        this.entityHandler
                            .uploadPicture(newEntity)
                            .then(uploadResponse => {
                            })
                            .catch(error => {
                                this.$log.error(error);
                            })
                            .finally(() => { });

                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                        this.$parent.fetchEntities();
                        this.closeDialog();
                    })
                    .catch(error => {
                        this.$log.error(error);
                    })
                    .finally(() => { });
            } else {
                const invalidField = this.$refs.form.inputs.filter(item => item.valid === false);
                if (invalidField) {
                    this.$nextTick(() => invalidField[0].focus());
                }
                this.$store.commit('setStatusCode', 'invalidFormData');
                this.$store.commit('setSnackbarEnabled', true);
            }
        },

        saveEditedEntity() {
            this.$log.debug('DialogBase of ' + this.constructor.name + ' - saveEditedEntity');
            if (this.$refs.form.validate()) {
                this.$log.debug(this.editedEntity);
                const editedEntity = this._saveEditedEntityCreateEntityObj(this.editedEntity);

                this.entityHandler
                    .updateEntity(editedEntity, false)
                    .then((response) => {

                        if (editedEntity.picture) {
                            this.entityHandler
                                .uploadPicture(editedEntity)
                                .then(uploadResponse => {

                                })
                                .catch(error => {
                                    this.$log.error(error);
                                })
                                .finally(() => { });
                        }


                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                        this.$parent.fetchEntities();
                        this.closeDialog();
                    })
                    .catch(error => {
                        this.$log.error(error);
                    })
                    .finally(() => { });
            } else {
                const invalidField = this.$refs.form.inputs.filter(item => item.valid === false);
                if (invalidField) {
                    this.$nextTick(() => invalidField[0].focus());
                }
                this.$store.commit('setStatusCode', 'invalidFormData');
                this.$store.commit('setSnackbarEnabled', true);
            }
        },

        deleteConfirm(index) {
            this.deleteAddressIndex = index;
            this.dialog.delete = true;
        },

        deleteSelectedEntity() {
            this.deleteAddress(this.deleteAddressIndex);
            this.dialog.delete = false;
        },

        selectPicture(pict) {
            this.editedEntity.picture = pict;
            this.editedEntity.image = URL.createObjectURL(pict);
        },
    },

    watch: {
        isOpenDetails() {
            if (this.isOpenDetails) {
                this.form.general = true;
                this.form.personalInformation = true;
                this.form.taxAndRegistration = true;
                this.form.addresses = true;
                this.form.roles = true;
                this.form.table = true;
                if (this.isEdit) {
                    //this.$refs.form.resetValidation();
                    this.openDialogDetails(this.entity);
                } else {
                    //this.$refs.form.resetValidation();
                    this.openDialogNew(this.entity);
                }

                this.$nextTick(() => {
                    this.$refs.form.resetValidation();
                    this.$refs.agent_form_last_name.focus();
                });
            }
        },
        
        // "editedEntity.partner_id": {
        //     handler: function (before, after) {
        //         this.partner.showDetails = null
        //         if (!!this.partners.find(el => el.id == this.editedEntity.partner_id)) {
        //             this.partner.selected = this.partners.find(el => el.id == this.editedEntity.partner_id)
        //         }
        //         //     this.initPartners();
        //     }
        // }
    }
}
