import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';
import utils from '@/components/Utils/utils';


export class SystemEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.SYSTEM,
        save: URLs.SYSTEM,
        update: URLs.SYSTEM,
        delete: URLs.SYSTEM,
        roles: URLs.ROLE,
        rolesOfSystem: URLs.SYSTEM_ROLE,
    };

    constructor(log, store) {
        super(log, store);
    }

    createEntityObj(entity = null) {
        let returnObj = {};

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : '';
        returnObj.system_id = isPresent(entity) && isPresent(entity.system_id) ? entity.system_id : '';
        returnObj.name = isPresent(entity) && isPresent(entity.name) ? entity.name : '';

        return returnObj;
    }

    listAll() {
        let url = this.apiURLs.listAll;
        this.log.debug('EntityBase of ' + this.constructor.name + ' - listall [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }

    systemRoles(entity, idFieldName = 'id') {
        let url = eval(`\`${this.apiURLs.roles}\``);
    
        this.log.debug('EntityBase of ' + this.constructor.name + ' - roles [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }

    roles(entity, idFieldName = 'id') {
        let systemId = entity.id;

        let url = eval(`\`${this.apiURLs.rolesOfSystem}\``);
    
        this.log.debug('EntityBase of ' + this.constructor.name + ' - roles [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }

}
