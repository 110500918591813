import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';
import utils from '@/components/Utils/utils';


export class PartnerEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.PARTNER,
        save: URLs.PARTNER,
        update: URLs.PARTNER,
        delete: URLs.PARTNER
    };
    serviceTypeId = 1;
    templatePartner = false;

    constructor(log, store) {
        super(log, store);
    }

    createEntityObj(entity = null) {
        let returnObj = {};

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : '';
        returnObj.name = isPresent(entity) && isPresent(entity.name) ? entity.name : '';
        returnObj.firm_name = isPresent(entity) && isPresent(entity.firm_name) ? entity.firm_name : '';
        returnObj.contact_name = isPresent(entity) && isPresent(entity.contact_name) ? entity.contact_name : '';
        returnObj.contact_phone = isPresent(entity) && isPresent(entity.contact_phone) ? entity.contact_phone : '';
        returnObj.contact_email = isPresent(entity) && isPresent(entity.contact_email) ? entity.contact_email : '';
        returnObj.comment = isPresent(entity) && isPresent(entity.comment) ? entity.comment : '';
        returnObj.active = isPresent(entity) && isPresent(entity.active) ? entity.active : 'Y';
        returnObj.created_at = isPresent(entity) && isPresent(entity.created_at) ? entity.created_at : '';
        returnObj.addresses = isPresent(entity) && isPresent(entity.addresses) ? entity.addresses : [];

        return returnObj;
    }

    listAll() {
        var url = this.apiURLs.listAll;
        this.log.debug('EntityBase of ' + this.constructor.name + ' - listall [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }
}
