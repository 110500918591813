import DataComponentBase from '@/commons/DataComponentBase';
import AgentDetails from '@/components/Agents/AgentDetails';
import { AgentEntity } from '@/components/Agents/AgentEntity';
import VerificationDialog from '@/components/CustomVerificationDialog';
import { isPresent } from '@/components/Utils/utils';


import moment from 'moment-timezone';

export default {
    name: 'AgentList',
    extends: DataComponentBase,
    components: {
        AgentDetails,
        VerificationDialog
    },
    props: {
        actions: {
            type: Boolean,
            default: true,
        },
        checkboxes: {
            type: Boolean,
            default: false,
        },
        // partnerId: {
        //     type: Number,
        // },
        partner: {
            type: Object,
            required: false,
        },
        selectedItems: {
            type: Array,
            default: ()=>[],
        },
        productId: {
            type: Number,
            default: null,
        },
        extraOffset: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            headerFilters: {
                id: "",
                active: "all",
                partner_name: "",
                last_name: "",
                first_name: "",
                phone: "",
                email: "",
                "products[0].expirationDate": "",
            },
            //selectedItems: [],
            // selectedItem: {},
            // dialog: {
            //   details: false,
            //   edit: false,
            //   delete: false
            // },
            selected: this.selectedItems,
            entityHandler: new AgentEntity(this.$log, this.$store),
            defaultPartner: {id: ""}
        }
    },

    created() {
        this.styleProps.windowWidth = window.innerWidth;
        this.styleProps.windowHeight = window.innerHeight;
        this.detectMobile();
        if (isPresent(this.$route.query.partner_id)){
            this.entityHandler.setPartnerId(this.$route.query.partner_id);
            this.entityHandler.fetchEntities();
        }
        // if (this.partner === null) {
        //     this.partner = this.defaultPartner;
        //     this.entityHandler.setPartnerId(this.partner.id);
        //     this.fetchEntities();
        // })
        //let urlParams = new URLSearchParams(window.location);
    },

    mounted() {
    },

    computed: {
        headers() {
            let headers = [
                {
                    text: this.$i18n.t('common.id'),
                    align: "right",
                    class: "width-89",
                    value: "id",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['id'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t("common.status"),
                    value: "active",
                    class: "width-105",
                    filterType: "selectSimple",
                    items: [
                        { text: this.$i18n.t("common.all"), value: "all" },
                        { text: this.$i18n.t("common.active"), value: "Y" },
                        { text: this.$i18n.t("common.inactive"), value: "N" },
                    ],
                    filter: f => {
                        if (this.headerFilters['active'] != "all") {
                            return f == this.headerFilters['active'];
                        } else {
                            return true;
                        }
                    }
                },
                {
                    text: this.$i18n.t('common.lastName'),
                    align: "left",
                    value: "last_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['last_name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.firstName'),
                    align: "left",
                    value: "first_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['first_name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.partner'),
                    align: "left",
                    value: "partner_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['partner_name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.phone'),
                    align: "left",
                    value: "phone",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['phone'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.email'),
                    align: "left",
                    value: "email",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['email'].toLowerCase()) }
                },
            ];

            if (this.productId != null) {
                headers.push(
                    {
                        text: this.$i18n.t('common.expirationDate'),
                        align: "left",
                        value: "products[0].expirationDate",
                        filterType: "text",
                        filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['products[0].expirationDate'].toLowerCase()) }
                    },
                )
            }

            if (this.actions) {
                headers.push(
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        align: "center",
                        class: "action-column-2",
                    }
                )
            }

            return headers;
        },
        itemForDelete() {
            let res = isPresent(this.selectedEntity) && isPresent(this.selectedEntity.last_name) && isPresent(this.selectedEntity.first_name) ?
                this.selectedEntity.last_name + ' ' + this.selectedEntity.first_name : '';
            return res;
        }
    },

    methods: {
        //   initAgentList() {
        //     this.tableItems = require('@/data/agents.json').data;
        //     if(this.partnerId != null) {
        //       this.tableItems = this.tableItems.filter(el => {
        //         return el.partnerId == this.partnerId;
        //       });
        //     }
        //     if(this.productId != null) {
        //       this.tableItems.forEach(el => {
        //         let actualProduct = el.products.find(product => product.id == this.productId);
        //         if(actualProduct != undefined) {
        //           el.products = [ actualProduct ];
        //         }
        //       })
        //       this.tableItems = this.tableItems.filter(el => {
        //         return el.products.length > 0
        //       });
        //     }
        //   },

        openDialogNew() {
            this.clearDialog();
            this.selectedEntity = {partner_id: this.partner.id}
            this.dialog.edit = false;
            this.dialog.details = true;
        },

        //   openDialogDetails(item) {
        //     this.selectedItem = JSON.parse(JSON.stringify(item));
        //     this.dialog.edit = true;
        //     this.dialog.details = true;
        //   },
        //   openDeleteDialog(item) {
        //     this.selectedItem = JSON.parse(JSON.stringify(item));
        //     this.dialog.delete = true;
        //   },
        //   closeDialog() {
        //     this.dialog.details = false;
        //     this.dialog.edit = false;
        //     this.dialog.delete = false;
        //     this.initAgentList();
        //   },

        deleteAgent(id) {},

        getStatus(isActive) {
            switch (isActive) {
                case "Y":
                    return { color: "success", text: "common.active" };
                    break;
                case "N":
                    return { color: "#525252", text: "common.inactive" };
                    break;
                default:
                    return { color: "error", text: "common.na" };
                    break;
            }
        },

        getExpirationDateState(expirationDate) {
            if (moment(expirationDate).isSameOrAfter(moment().add(7, 'days'))) {
                return { color: "success", icon: "mdi-circle" };
            } else if (moment(expirationDate).isSameOrAfter(moment())) {
                return { color: "warning", icon: "mdi-circle" };
            } else if (moment(expirationDate).isBefore(moment())) {
                return { color: "error", icon: "mdi-circle" };
            } else {
                return { color: "", icon: "mdi-circle" };
            }
        },

    },

    watch: {
        // 'dialog.details'() {
        //     if (!this.dialog.details) {
        //         this.closeDialog();
        //     }
        // },
        selected() {
            this.$emit('update:selectedItems', this.selected);
        },
        partner() {
            this.entityHandler.setPartnerId(this.partner.id);
            this.fetchEntities();
        },
        selectedItems() {
            this.selected = this.selectedItems;
        },
    }

}
